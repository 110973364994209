import { Http, HttpAuth } from '../../config/Http'
import { changeLoading } from './loading.action'
import { changeNotify } from './notify.action'

export const actionTypes = {
    CHANGE: 'TIPOJOGO_CHANGE',
    ERROR: 'TIPOJOGO_ERROR',
    SUCCESS: 'TIPOJOGO_SUCCESS',
    OPENDELETE: 'TIPOJOGO_OPENDELETE',
    SHOW:'TIPOJOGO_SHOW',
    INDEX:'TIPOJOGO_INDEX',
}

export const change = (payload) => ({
    type: actionTypes.CHANGE,
    payload
})

export const error = (payload) => ({
    type: actionTypes.ERROR,
    payload
})

export const success = (payload) => ({
    type: actionTypes.SUCCESS,
    payload
})

export const opendelete = (payload) => ({
    type: actionTypes.OPENDELETE,
    payload
})


export const indexResponse = (payload) => ({
    type: actionTypes.INDEX,
    payload
})

export const showResponse =(payload) => ({
    type: actionTypes.SHOW,
    payload,
})

export const index = () => dispatch => {
    return HttpAuth.get('/tipojogo')
    .then(res => typeof res !== 'undefined' && dispatch(indexResponse(res.data)))
}

export const setUserToken = token => dispatch => {
    localStorage.setItem('blazeadmin_token', token);
    dispatch( change({
        nome: '',
        email: '',
        senha: '',
        telefone:'',
    }) )

    dispatch(success(true))
}


export const store = data => dispatch => {
    dispatch(changeLoading({
        open: true,
        msg: 'Cadastrando Jogos...'
    }));
  
    return HttpAuth.post('/tipojogo', data)
        .then(res => {
            
            dispatch( changeLoading({open: false}) )
            if(typeof res !== 'undefined'){
                if(res.data.error){
                    dispatch(success(false));
                    dispatch(error(res.data.error));
                }
   
                if(res.status === 201){
                    dispatch(success(true));
                    dispatch( changeNotify({
                        open: true,
                        class: 'success',
                        msg: res.data.msg
                    }) )
                   
                }
            }
        })
        .catch(error => {
        
            dispatch( changeLoading({open: false}) )

            if(error.response) {
                dispatch(error(error.response.data.error))
            }
        })
}

export const update = (data) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Jogos'
    }))

    return HttpAuth.put('/tipojogo/update/'+data.id,data)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                         
                      }
                 }
           })
}


export const show = (id) => dispatch => {
    return HttpAuth.get('/tipojogo/show/'+id)
    .then(res => typeof res !== 'undefined' && dispatch(showResponse(res.data)))
}




export const deletar = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Excluindo Jogos'
    }))

    return HttpAuth.delete(`tipojogo/delete/${id}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}

export const zerarwinloss = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Zerando ... '
    }))

    return HttpAuth.put(`tipojogo/zerarwinloss/${id}`)
           .then(res =>{
              
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                    if(res.data.error){
                        dispatch(success(false));
                        dispatch(error(res.data.error));
                    }

                      if(res.status === 201){
                          dispatch(success(true));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}


export const mudastatus = (id) => dispatch =>{
    dispatch(changeLoading({
        open:true,
        msg:'Atualizando Status'
    }))
 
    return HttpAuth.put('tipojogo/mudastatus/'+id)
           .then(res =>{
                 dispatch(changeLoading({open:false}) );
                 if(typeof res !== 'undefined'){
                      if(res.data.error){
                          dispatch(success(false));
                          dispatch(error(res.data.error));
                      }

                      if(res.status === 201){
                        dispatch(success(false));
                          dispatch(changeNotify({open:true,msg:res.data.msg}));
                          dispatch(index());
                         
                      }
                 }
           })
}













