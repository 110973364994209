import {combineReducers} from 'redux';
import  loadinReducers from './loading.reducer';
import  notifyReducers from './notify.reducer';
import  alertReducers from './alert.reducer';

import usuarioReducers from './usuario.reducer';
import permissaoReducers from './permissao.reducer'
import authReducers from './auth.reducer';
import tipojogoReducers from './tipojogo.reducer';
import grupoReducers from './grupo.reducer';
import mensagemReducers from './mensagem.reducer';
import estrategiaReducers from './estrategia.reducer';
import roletaReducers from './roleta.reducer';
    


const rootReducer = combineReducers({
    loadinReducers,
    notifyReducers,
    alertReducers,
    usuarioReducers,
    permissaoReducers,
    authReducers,
    tipojogoReducers,
    grupoReducers,
    mensagemReducers,
    estrategiaReducers,
    roletaReducers,
})

export default rootReducer;