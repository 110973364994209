import {actionTypes} from '../actions/estrategia.action';

const initialState = {
    dubles:{
        data:[]
    },
    duble:{
      
    },
    list:[],
    

    crashs:{
        data:[]
    },
    crash:{
      
    },

    crashpremiums:{
      
    },
    crashpremium:{
      
    },
    doublepremiums:{
      
    },
    doublepremium:{
      
    },
    grupopremium:{},

    fantans:{
        data:[]
    },
    fantan:{
      
    },
    futballstudios:{
        data:[]
    },

    futballstudio:{

    },

    aviators:{
        data:[]
    },

    aviator:{

    },

    penalty:{

    },
    miner:{

    },
    fortunetiger:{

    },  
    
    success:false,
    error:{},
 
    status:[],
    
}


export default (state = initialState, { type, payload }) => {
    switch (type) {

    case actionTypes.INDEX:
      
        return { ...state, ...payload }

    case actionTypes.SHOW:
      
        return { ...state, ...payload }
      
    case actionTypes.CHANGE:
        return{ 
            ...state,
            duble:{
                 ...state.duble,
                 ...payload
             }
    }
    case actionTypes.CHANGEFORTUNETIGER:
        return{ 
            ...state,
            fortunetiger:{
                    ...state.fortunetiger,
                    ...payload
                }
    }
    case actionTypes.CHANGEMINER:
        return{ 
            ...state,
            miner:{
                    ...state.miner,
                    ...payload
                }
    }
              
    case actionTypes.CHANGELIST:
        return{ 
            ...state,
            list:[
                 ...state.list,
                {
                 ...payload
                }
                ]
        }

   

    case actionTypes.CLEARLIST:
        return{ 
           ...state,
           list:[
                   
                ]
            }

    case actionTypes.DELETELIST:        
        return{ 
            ...state,
            list:[
               
                ...state.list.filter((item) => item.id !== payload),
                
            ]
    }


    case actionTypes.CHANGECRASH:
        return{ 
            ...state,
            crash:{
                 ...state.crash,
                 ...payload
             }
        }
    case actionTypes.CHANGECRASHPREMIUM:
        return{ 
            ...state,
            crashpremium:{
                    ...state.crashpremium,
                    ...payload
                }
        }
    case actionTypes.CHANGEDOUBLEPREMIUM:
            return{ 
                ...state,
                doublepremium:{
                        ...state.doublepremium,
                        ...payload
                    }
        }
    case actionTypes.CHANGEFANTAN:
        return{ 
            ...state,
            fantan:{
                    ...state.fantan,
                    ...payload
                }
    }
    case actionTypes.CHANGEAVIATOR:
        return{ 
            ...state,
            aviator:{
                    ...state.aviator,
                    ...payload
                }
    }
    case actionTypes.CHANGEFUTBALLSTUDIO:
        return{ 
            ...state,
            futballstudio:{
                    ...state.futballstudio,
                    ...payload
                }
    }

    case actionTypes.CHANGEPENALTY:
        return{ 
            ...state,
            penalty:{
                    ...state.penalty,
                    ...payload
                }
    }

    case actionTypes.SUCCESS:
        return{ 
            ...state, 
            success: payload
         }
    case actionTypes.ERROR:
        return{
            ...state,
            error: payload
        }
    default:
        return state
    }
}
